.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.392);
}

.centerPage {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Page {
    height: 100%;
    width: 100%;
    position: relative;
}

/* fade */
/* 向左滑入 */
.SwipePage {
    position: absolute;
    transform: translateX(100%);
    background-color: aquamarine;
}

.SwipePageEnterActive {
    transform: translateX(0%);
    transition: transform 150ms;
}

.SwipePageEnterDone {
    transform: translateX(0%);
}

.SwipePageExit {
    transform: translateX(0%);
}

.SwipePageExitActive {
    transform: translateX(100%);
    transition: transform 150ms;
}
/* 向右滑入 */
.SwipeRPage {
    position: absolute;
    transform: translateX(-100%);
}

.SwipeRPageEnterActive {
    transform: translateX(0%);
    transition: transform 150ms;
}

.SwipeRPageEnterDone {
    transform: translateX(0%);
}

.SwipeRPageExit {
    transform: translateX(0%);
}

.SwipeRPageExitActive {
    transform: translateX(-100%);
    transition: transform 150ms;
}
/* 彈跳出現 */
.PopupPage {
    opacity: 0;
    transform: scale(0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.PopupPageEnterActive {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 150ms, transform 150ms;
}

.PopupPageEnterDone {
    opacity: 1;
    transform: translateX(0);
}

.PopupPageExit {
    opacity: 1;
    transform: translateX(0);
}

.PopupPageExitActive {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 150ms, transform 150ms;
}

/* 透明度顯現 */
.Opacity {
    /* background-color: rgba(255, 255, 255, 0.596); */
    opacity: 0;
}

.OpacityEnterActive {
    opacity: 1;
    transition: opacity 150ms;
}

.OpacityEnterDone {
    opacity: 1;
}

.OpacityExit {
    opacity: 1;
}

.OpacityExitActive {
    opacity: 0;
    transition: opacity 150ms;
}

/* 向上 */
.UpPage {
    transform: translateY(100%);
}

.UpPageEnterActive {
    transform: translateY(0%);
    transition: transform 150ms;
}

.UpPageEnterDone {
    transform: translateY(0%);
}

.UpPageExit {
    transform: translateY(0%);
}

.UpPageExitActive {
    transform: translateY(100%);
    transition: transform 300ms;
}

/* 向下 */
.DownPage {
    transform: translateY(-100%);
}

.DownPageEnterActive {
    transform: translateY(0%);
    transition: transform 300ms;
}

.DownPageEnterDone {
    transform: translateY(0%);
}

.DownPageExit {
    transform: translateY(0%);
}

.DownPageExitActive {
    transform: translateY(-100%);
    transition: transform 300ms;
}