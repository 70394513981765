.app {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;

    @media screen and (min-width: 767px) {
        width: 767px;
        height: 400px;
        padding: 2rem;
        >h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.2em;
            color: #272727;
        }
    
        .detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 18px;
    
            >div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 95px;
    
                >span {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    letter-spacing: 0.2em;
                    color: #1C1B1B;
                }
            }
        }
    
        .btns {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
    
            >button {
                width: 40%;
            }
        }
    
        >span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.2em;
            color: #000000;
        }
    }

    @media screen and (max-width: 767px) {
        width: 90vw;
        height: 50vh;
        >h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: #272727;
        }
    
        .detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 18px;
    
            >div {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 95px;
    
                >span {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    letter-spacing: 0.2em;
                    color: #1C1B1B;
                }
            }
        }
    
        .btns {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            gap: 1rem;
            >button {
                width: 85%;
            }
        }
    
        >span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            padding: 0 1rem;
        }
    }

    
}