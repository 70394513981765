.app {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;

    @media screen and (max-width: 767px) {
        width: 90vw;
        position: relative;
        .btns {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            gap: 1.4rem;
            padding: 5rem 0 3rem 0;
            >div {
                display: flex;
                justify-content: flex-start;
                padding-left: 2px;
                width: 76%;
                border-style: solid;
                border-width: 0px;
                border-bottom-width: 2px;
                height: 2rem;
            
            }
        }
        .close {
            position: absolute;
            top: 1.2rem;
            left: 1.8rem;
        }
    }

    
}