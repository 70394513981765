.app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;

    @media screen and (min-width: 767px) {
        width: 767px;
        height: 400px;
        padding: 2rem;
    }

    @media screen and (max-width: 767px) {
        width: 90vw;
        height: 50vh;
    }
    @media screen and (min-width: 767px) {
        >h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.2em;
            color: #272727;
        }
    }
    @media screen and (max-width: 767px) {
        >h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 29px;
            text-align: center;
            letter-spacing: 0.2em;
            color: #272727;
            padding: 0 0.5rem;
        }
    }

    @media screen and (min-width: 767px) {
        .btns {
            padding-top: 3rem;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            gap: 1rem;
            >button {
                width: 40%;
            }
        }
    }

    @media screen and (max-width: 767px) {
        .btns {
            padding-top: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            gap: 1rem;
            >button {
                width: 85%;
            }
        }
    }
    >span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.2em;
        color: #000000;
        padding: 0 0.5rem;
    }
}