.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: fixed;
    width: 100%;
}

.canvas {
    position: relative;
    display: flex;
    justify-content: center;

    .reactThree {
        width: 100%;
        background-color: #E5E5E5;
    }
}

.bottomBox {
    position: absolute;
    bottom: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .teachInfo {
        padding: 16px 10px;
        background: rgb(235, 216, 215);
        border-radius: 15px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.2em;

    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;

        @media screen and (min-width: 767px) {
            width: auto;
        }

        @media screen and (max-width: 767px) {
            max-width: 90vw;
            overflow-x: auto;
        }

        height: 41px;
        background: #FFFFFF;
        border-radius: 68px;
    }
}


.buttonSelect {
    width: 162px;
    height: 41px;
    background: #1C1B1B;
    border-radius: 68px;
    flex: none;
    flex-grow: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
}

.button {
    width: 162px;
    height: 41px;
    background: #FFFFFF;
    border-radius: 68px;
    flex: none;
    flex-grow: 1;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #000000;
    border-width: 0px;
}

.topMenu {
    position: absolute;

    @media screen and (min-width: 767px) {
        top: 32px;
        left: 55px;
    }

    @media screen and (max-width: 767px) {
        top: 32px;
        left: 35px;
    }
    gap: 1rem;
    display: flex;
    align-items: center;
}

.help {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


    >span {
        padding-left: 24px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #1c1b1b;
    }

    @media screen and (min-width: 767px) {
        >img {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        >span {
            display: none;
        }
    }

}

.close {
    font-size: 30px;
    color: #D5D5D5;
}



.info {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    max-width: 140px;
    height: 140px;
    background: rgba(239, 245, 251, 0.8);
    border-radius: 68px;
    /* text-align: center; */
    margin-right: 4px;
}

@media screen and (min-width: 767px) {
    .bottom {
        display: flex;
        flex-direction: row;
        width: 100vw;
        align-items: center;
        justify-content: flex-start;
        padding: 10px 42px;
        .menu_tag {
            display: flex;
            flex-direction: column;
            width: calc(100vw - 50px);

            .tags {
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-bottom: 12px;

                .tag {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: center;
                    width: 140px;
                    border-width: 2px;
                    border-bottom: solid #D4D4D4;
                    margin-right: 12px;
                    padding: 6px;
                    padding-top: 15px;
                    font-size: 12px;
                }

                .tagSelect {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: center;
                    width: 140px;
                    border-width: 2px;
                    border-bottom: solid #272727;
                    margin-right: 12px;
                    padding: 6px;
                    padding-top: 15px;
                    font-size: 12px;
                }
            }

            .menu {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                overflow-x: scroll;

                >.ballDiv {
                    display: flex;
                    flex-direction: column;
                    font-size: 8px;
                    margin: 3px;
                    border-radius: 4px;
                    align-items: center;
                    justify-content: space-around;
                    width: 134px;
                    min-width: 134px;
                    border: 2px solid #ffffff;

                    &:hover {
                        //border: 2px solid #000000;
                    }

                    &.hovered {
                        border: 2px solid #000000;
                    }

                    >p {
                        height: 1rem;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        line-height: 12px;
                        text-align: center;
                        font-size: .6rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .bottom {
        display: flex;
        flex-direction: row;
        width: 100vw;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 2px;
        flex-grow: 1;
        align-items: flex-start;
        .menu_tag {
            display: flex;
            flex-direction: column;
            width: calc(100vw);

            .tags {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                height: 30px;
                overflow-x: scroll;
                overflow-y: hidden;

                .tag {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: center;
                    border-width: 2px;
                    border-bottom: solid #D4D4D4;
                    margin-right: 12px;
                    padding: 6px;
                    white-space: nowrap;
                    font-size: 12px;
                }

                .tagSelect {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    text-align: center;
                    border-width: 2px;
                    border-bottom: solid #272727;
                    margin-right: 12px;
                    padding: 6px;
                    white-space: nowrap;
                    font-size: 12px;
                }
            }

            .menu {
                align-content: flex-start;
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                height: 160px;
                overflow-x: scroll;
                overflow-y: hidden;
                width: 100%;

                >.ballDiv {
                    display: flex;
                    flex-direction: column;
                    font-size: 8px;
                    margin: 3px;
                    border: 1px solid #F2F2F2;
                    border-radius: 4px;
                    align-items: center;
                    justify-content: flex-start;
                    width: 22vw;
                    min-width: 22vw;
                    height: 43%;
                    border: 2px solid #ffffff;
                    &:hover {
                        //border: 2px solid #000000;
                    }

                    &.hovered {
                        border: 2px solid #000000;
                    }

                    .name {
                        display: none;
                    }

                    >p {
                        height: 1rem;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        line-height: 12px;
                        text-align: center;
                        font-size: .8rem;
                    }
                }
            }
        }
    }
}

.info>div {
    width: 80%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.2em;
}




.nextDiv {
    display: flex;
    flex-direction: column;
    position: absolute;

    @media screen and (min-width: 767px) {
        right: 48px;
        top: 31px;
    }

    @media screen and (max-width: 767px) {
        right: 28px;
        top: 31px;
    }

    align-items: flex-end;
}

.selPendants {
    position: absolute;
    right: 200px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #000000;
    bottom: 5%;
}

.previewBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media screen and (min-width: 767px) {
        width: 160.48px;
        //height: 25px;
        padding: 10px;
    }

    @media screen and (max-width: 767px) {
        padding: 5px;
        width: 36vw;
    }
    border-width: 0px;
    background: #272727;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #FFFFFF;
    border-radius: 2px;

    &:disabled {
        background-color: #c4c4c4;
    }
}

.previewPrice {
    width: 107px;
    height: 23px;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: end;
    padding-top: 12px;
}


.loadingBar {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgb(255, 255, 255);
    height: 100%;
}

.loadingBar>div:nth-child(1) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 25px;
}

.loadingBar>div:nth-child(1)>p {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}

.loadingBar>div:nth-child(2) {
    border: 2px solid #000000 !important;
    width: 280px;
    height: 16px;
}


.dragimg {
    width: 20px;
}

.modal_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 360px;
}

.modal_detail {
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    width: 460px;
}

.modal_detail>* {
    margin: .4rem;
}

.modal_detail>button {
    align-self: stretch;
    background: #1c1b1b;
    border: 1px solid #aaa;
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    gap: 10px;
    height: 35px;
    line-height: 15px;
    margin-top: 12px;
    order: 2;
    text-align: center;
}

.modal_detail>button:hover {
    background: lightgray;
    color: #1C1B1B;
}

.modal_detail>div>input {
    padding-left: 14px;
    font-size: 20px;
    text-transform: uppercase;
}

.modal_icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.modal_icons>div {
    font-size: 1.4rem;
    padding: 0rem .6rem;
}

.SelectLabel {
    width: 50px;
    white-space: nowrap;
}

.SelectGuide {
    padding-left: 5px;
}