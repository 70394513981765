$color_1: #1c1b1b;
$color_2: white;
$color_3: #000000;
$color_4: #FFFFFF;
$color_5: #1C1B1B;
$font-family_1: 'Montserrat';
$font-family_2: 'Nunito Sans';
$background-color_1: #F7F7F7;
$background-color_2: white;

.app {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $background-color_1;
	min-height: 100vh;
	height: 100%;
	.cartBtn {
		margin-top: 50px;
		width: 320px;
	}
	>h1 {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-top: 50px;
		margin-bottom: 16.5px;
		color: $color_1;
	}

	>h2 {
		font-family: $font-family_2;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 23px;
		text-align: center;
		margin-bottom: 38px;
		margin-top: 0;
		color: $color_1;
	}

	.products {
		max-width: 86%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(22vw, 1fr));
		padding-bottom: 2rem;
		>div {
			width: 22vw;
			height: 15.81vw;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			margin: 30px 30px 0 30px;
	
			>span {
				font-family: $font-family_1;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
				letter-spacing: 0.2em;
				color: $color_1;
				padding-top: 30px;
				text-transform: uppercase;
			}
		}
	}
	
	.productsMobile {
		max-width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(40vw, 1fr));
		overflow-y: scroll;
		height: 50vh;
		>div {
			width: 40vw;
			height: 44.4vw;
			margin: 12px;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			margin: 5vw 5vw 0 5vw;
	
			>span {
				font-family: $font-family_1;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
				letter-spacing: 0.2em;
				color: $color_1;
				padding-top: 20px;
				text-transform: uppercase;
			}
		}
	}
	
	.ringProducts {
		max-width: 86%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(22vw, 1fr));
		padding-bottom: 2rem;
	
		>div {
			width: 22vw;
			justify-content: flex-start;
			align-items: center;
			display: flex;
			flex-direction: column;
			margin: 30px 30px 0 30px;
	
			.imgBox {
				width: 22vw;
				height: 16vw;
				justify-content: center;
				align-items: center;
				display: flex;
	
				>img {
					width: 90%;
					cursor: pointer;
				}
			}
	
			span {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				text-align: center;
				letter-spacing: 0.2em;
				color: #272727;
			}
	
			.viewList {
				padding-top: 40px;
				justify-content: center;
				align-items: center;
				display: flex;
				cursor: pointer;
				>span {
					font-size: 12px;
					line-height: 15px;
					color: #000000;
					padding-right: 20px;
				}
			}
	
		}
	}
	
	.ringProductsMobile {
		max-width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(40vw, 1fr));
		overflow-y: scroll;
		height: 50vh;
		>div {
			width: 40vw;
			height: 44.4vw;
			margin: 12px;
			justify-content: center;
			align-items: center;
			display: flex;
			flex-direction: column;
			margin: 5vw 5vw 0 5vw;
	
			.imgBox {
				width: 100%;
				justify-content: center;
				align-items: center;
				display: flex;
	
				>img {
					width: 90%;
					cursor: pointer;
				}
			}
	
			>span {
				font-family: $font-family_1;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				text-align: center;
				letter-spacing: 0.2em;
				color: $color_1;
				padding-top: 20px;
				text-transform: uppercase;
			}
			.viewList {
				padding-top: 1rem;
				justify-content: center;
				align-items: center;
				display: flex;
				cursor: pointer;
				>span {
					font-size: 10px;
					color: #000000;
				}
			}
		}
	}
}



.messageBtn {
	position: fixed;
	left: 20px;
	bottom: 20px;
	padding: 10px 15px;
	font-size: 20px;
	background: #777;
	color: $color_2;
	cursor: pointer;
}

.modal_detail {
	display: flex;
	flex-direction: column;
	flex-grow: .3;

	>* {
		margin-bottom: 12px;
	}

	>span {
		margin-bottom: 24px;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.2em;
		color: $color_3;
		flex: none;
		order: 0;
		flex-grow: 0;
	}

	>button {
		margin-top: 12px;
		gap: 10px;
		height: 35px;
		background: #1C1B1B;
		flex: none;
		order: 2;
		align-self: stretch;
		flex-grow: 0;
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-align: center;
		letter-spacing: 0.2em;
		color: $color_4;
		border: 1px solid #aaaaaa;
		border-radius: 2px;

		&:hover {
			background: lightgray;
			color: $color_5;
		}
	}
}

.selectDiv {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 16px;
	gap: 47px;
	width: 374px;
	height: 46px;
	border: 1px solid #D5D5D5;
	align-items: center;
	background-color: $background-color_2;
	font-family: $font-family_2;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 23px;
}

.SelectDiv_row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	>a {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.2em;
		text-decoration-line: underline;
		color: $color_3;
	}
}

.SelectLabel {
	width: 50px;
	white-space: nowrap;
	padding-left: 16px;
}

.SelectGuide {
	padding-left: 5px;
}