@mixin btn1() {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 45px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
}

.btnBlack {
    @include btn1();
    width: 275px;
    background: #25241E;
    border: 0px;
    color: #FFFFFF;

    &:disabled,
    &:disabled:hover {
        background: #7f7e7e;
        border: 1px solid #e6e2e2;
        color: #515050;
    }

    &:hover {
        background: #FFFFFF;
        border: 1px solid #000000;
        color: #000000;
    }
}

.btnWhite {
    @include btn1();
    width: 275px;
    background: #FFFFFF;
    border: 1px solid #000000;
    color: #000000;
    &:disabled,
    &:disabled:hover {
        background: #7f7e7e;
        border: 1px solid #e6e2e2;
        color: #515050;
    }

    &:hover {
        background: #25241E;
        border: 0px;
        color: #FFFFFF;
    }
}
