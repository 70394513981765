.app {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @media screen and (min-width: 767px) {
    height: 107px;
    }
    @media screen and (max-width: 767px) {
        height: 12vh;
    }
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    padding: 0 3%;
    .empty {
        width: 20%;
    }
    .lang {
        position: absolute;
        left: 1rem;
        top: 1rem;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: left;
        letter-spacing: 0.2em;
        color: #fbfbfb;
        width: 20%;
    }
    .option {
        width: 20%;
    }
    .logo {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        >img {
            height: 60%;
        }
    }

    @media screen and (min-width: 767px) {
    .cart {
        width: 20%;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: right;
        letter-spacing: 0.2em;
        color: #1c1b1b;
    }
    .cartImg {
        display: none;
    }
    }
    @media screen and (max-width: 767px) {
        .cart {
            display: none;
        }
        .cartImg {
            text-align: right;
            width: 20%;
        }
    }
}

