.app {
    border-radius: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background-color: white;
    border-style: solid;
    border-width: 1px;

    @media screen and (min-width: 767px) {
        width: 1182px;
        height: 673px;
    }

    @media screen and (max-width: 767px) {
        width: 90vw;
        height: 70vh;
    }

    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #000000;
    }

    @media screen and (min-width: 767px) {
        .pic {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 995px;
            padding-top: 60px;
            padding-bottom: 60px;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                >span {
                    padding-top: 20px;
                }

            }
        }
    }

    @media screen and (max-width: 767px) {
        .pic {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            height: 60%;
            overflow-y: scroll;

            >div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                >span {
                    padding-top: 20px;
                    width: 95%;
                    text-align: center;
                }

                >img {
                    pointer-events: none;
                }
            }
        }
    }



    .checkDiv {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1.4rem 0;

        label {
            padding-left: 10px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.2em;
            text-transform: capitalize;
            color: #000000;
        }
    }

    >button {
        background: #1c1b1b;
        border: 1px solid #aaa;
        border-radius: 2px;
        color: #fff;
        font-size: 12px;
        gap: 10px;

        @media screen and (min-width: 767px) {
            width: 374px;
        }

        @media screen and (max-width: 767px) {
            width: 95%;
        }

        height: 35px;
        line-height: 35px;
        margin-top: 12px;
        order: 2;

        &:hover {
            background: lightgray;
            color: #1C1B1B;
        }
    }

}