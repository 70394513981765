.app {
    display: flex;
    flex-direction: row;
    align-items: center;

    justify-content: center;
    gap: 17px;

    width: 100vw;
    height: 54px;
    min-height: 54px;
    left: 0px;
    top: 0px;
    background: #680F11;
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    a {
        color: #FFFFFF;
        font-weight: 800;
    }
    a:visited {
        color: white; /* 访问过后的链接颜色 */
    }
}

.time {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    width: 70px;
    height: 20px;
    background: #7F7F7F;
    border-radius: 2px;

    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
}