.teachTip {
    position: absolute;
    padding: 16px 10px;
    background: rgb(235, 216, 215);
    border-radius: 15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.2em;
}