$color_1: #000000;
$color_2: #FFFFFF;
$color_3: #333333;
$font-family_1: 'Montserrat';
$font-family_2: 'Nunito Sans';
$background-color_1: #F7F7F7;
$background-color_2: #F7F7F7;

@mixin font1() {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
}

@media screen and (min-width: 767px) {
    .app {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $background-color_1;
        height: 100vh;

        h1 {
            margin-top: 60px;
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.22em;
            color: $color_1;
        }

        h2 {
            font-family: $font-family_2;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: $color_1;
        }

        >.page1View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;


            .inputArea {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                padding-top: 3rem;
                gap: 2rem;

                .upload {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1.4rem;

                    .list {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 1rem;

                        >label {
                            @include font1();
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            border: 1px solid #000000;
                            border-radius: 8px;
                            width: 266px;
                            height: 61px;
                            cursor: pointer;

                            >input {
                                display: none;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }



                    >span {
                        @include font1();
                        letter-spacing: 0.09em;
                        width: 266px;
                    }
                }

                .ideas {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 0rem;

                    >textarea {
                        resize: none;
                        width: 757px;
                        height: 361px;
                        padding: 1rem;
                    }

                    .btns {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding-top: 24px;
                        gap: 94px;
                    }
                }
            }

        }

        >.page2View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            .slider {
                margin-top: 160px;
            }

            .budgetRange {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 724px;
                margin-top: 30px;

                .limitLabel {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 212px;
                    height: 35px;
                    box-sizing: border-box;
                    border: 1px solid;
                    padding-right: 20px;
                    background-color: #FFFFFF;

                    &.disabled {
                        color: #7F7F7F;
                        background-color: #C4C4C4;
                    }

                    >span {
                        width: 92px;
                        text-align: center;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                    }
                }

                .line {
                    flex-grow: 1;
                    border-style: dashed solid none none;
                    border-width: 1px;

                    &.disabled {
                        color: #7F7F7F;
                    }
                }

            }

            .checkbox {
                margin-top: 40px;
            }

            .btns {
                margin-top: 70px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-top: 24px;
                gap: 92px;

                >button {
                    width: 275px;
                }
            }

        }


        >.page3View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            .selectDay {
                margin-top: 60px;
                display: flex;
                flex-direction: row;
                gap: 165px;

                .timePickerBox {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    >img {
                        width: 18px;
                        height: 18px;
                    }

                    .timePicker {
                        background-color: #ffffff;
                        padding: 5px .5rem;
                        border: 1px solid;
                        border-radius: 5px;

                        &.disabled {
                            color: #7F7F7F;
                            border-color: #7F7F7F;
                            background-color: transparent;
                        }
                    }
                }
            }


            .btns {
                margin-top: 70px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding-top: 260px;
                gap: 92px;

                >button {
                    width: 275px;
                }
            }

        }

        >.page4View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            .info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: 30vh;
                padding-top: 60px;

                .inputBox {
                    .box {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        border: 2px solid #666;
                        width: 600px;
                        height: 60px;
                        border-radius: 5px;
                        background-color: transparent;
                        padding-left: 1rem;

                        >label {
                            width: 100px;
                            color: #7F7F7F;
                        }

                        >input {
                            @include font1();
                            text-align: left;
                            font-size: 18px;
                            width: 100%;
                            background-color: transparent;
                            color: #424040;
                        }

                        >div {
                            input {
                                width: 100%;
                                background-color: transparent;
                                border-width: 0px;
                            }
                        }
                    }

                    >span {
                        color: rgb(180, 27, 27);
                        padding-left: 1rem;
                    }
                }
            }


            .btns {
                margin-top: 70px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 92px;

                >button {
                    width: 275px;
                }
            }

        }

        >.page5View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            >h1 {
                margin-top: 240px;
            }

            >h2 {
                white-space: pre-wrap;
            }

            .btns {
                margin-top: 170px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 92px;

                >button {
                    width: 624px;
                }
            }

        }

    }

}

@media screen and (max-width: 767px) {
    .app {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $background-color_1;
        min-height: 100vh;

        h1 {
            margin-top: 20px;
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.22em;
            color: $color_1;
            width: 80%;
            text-align: center;
        }

        h2 {
            font-family: $font-family_2;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: $color_1;
            width: 80%;
            text-align: center;
        }

        >.page1View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            .inputArea {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 2rem;

                .upload {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    gap: .5rem;

                    .list {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: .5rem;
                        width: 95%;

                        >label {
                            @include font1();
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            box-sizing: border-box;
                            border: 1px solid #000000;
                            border-radius: 8px;
                            width: 90%;
                            height: 40px;
                            cursor: pointer;

                            >input {
                                display: none;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }

                    >span {
                        @include font1();
                        letter-spacing: 0.09em;
                        width: 90%;
                    }
                }

                .ideas {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 0rem;

                    >textarea {
                        resize: none;
                        width: 85%;
                        height: 20vh;
                    }

                    .btns {
                        width: 100vw;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding-top: 24px;
                        padding-bottom: 1rem;
                        gap: 1rem;

                        >button {
                            width: 85%;
                        }
                    }
                }
            }

        }

        >.page2View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;
            background-color: $background-color_2;

            >.ctrl {
                width: 80%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid #000000;
                border-radius: 3%;
                margin-top: 40px;

                .budgetRange {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    width: 40%;
                    height: 200px;
                    gap: 2rem;
                    padding-left: 1rem;

                    .limitLabel {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 44%;
                        height: 35px;
                        box-sizing: border-box;
                        gap: 6px;

                        >span {
                            text-align: center;
                            font-family: 'Montserrat';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                        }

                        >span:nth-child(1) {
                            color: #666;
                        }

                    }

                    .line {
                        border-style: dashed;
                        border-width: 1px;
                        width: 70%;
                    }

                }

                .slider {
                    height: 170px;
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }


            }

            .checkbox {
                margin-top: 40px;
            }

            .btns {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 36px;
                padding-bottom: 1rem;
                gap: 1rem;

                >button {
                    width: 85%;
                }
            }

        }

        >.page3View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            .selectDay {
                margin-top: 60px;
                display: flex;
                flex-direction: row;
                gap: 50px;

                .timePickerBox {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    >img {
                        width: 18px;
                        height: 18px;
                    }

                    .timePicker {
                        background-color: #ffffff;
                        padding: 5px .5rem;
                        border: 1px solid;
                        border-radius: 5px;
                        width: 90px;

                        &.disabled {
                            color: #7F7F7F;
                            border-color: #7F7F7F;
                            background-color: transparent;
                        }
                    }
                }
            }


            .btns {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 1rem;
                gap: 1rem;

                >button {
                    width: 85%;
                }
            }

        }

        >.page4View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            .info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                height: 30vh;
                padding-top: 30px;

                .inputBox {
                    .box {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        border: 2px solid #666;
                        width: 80vw;
                        height: 50px;
                        border-radius: 5px;
                        background-color: transparent;
                        padding-left: 1rem;

                        >label {
                            width: 100px;
                            color: #7F7F7F;
                        }

                        >input {
                            @include font1();
                            text-align: left;
                            font-size: 18px;
                            width: 100%;
                            background-color: transparent;
                            color: #424040;
                        }

                        >div {
                            input {
                                width: 100%;
                                background-color: transparent;
                                border-width: 0px;
                            }
                        }
                    }

                    >span {
                        color: rgb(180, 27, 27);
                        padding-left: 1rem;
                    }
                }
            }


            .btns {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 1rem;
                gap: 1rem;

                >button {
                    width: 85%;
                }
            }

        }

        >.page5View {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: $background-color_2;

            >h1 {
                margin-top: 240px;
            }

            .btns {
                width: 100vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 24px;
                padding-bottom: 1rem;
                gap: 1rem;

                >button {
                    width: 85%;
                }
            }

        }

    }


}