.app {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* border-style: solid;
    border-width: 1px;
    border-color: #999; */
    background-color: #ddd;
    overflow: hidden;
    @media screen and (min-width: 767px) {
        width: 600px;
    }
    @media screen and (max-width: 767px) {
        width: 84vw;
    }
    >img {
        width: 100%;
    }
    .cuff1 {
        transform: translate(0%, -40%);
        position: absolute;
        font-size: 2rem;
        letter-spacing: .6rem;
        text-align: center;
        width: 480px;
        word-wrap: break-word;
        line-height: 1.6rem;
    }
   
    .cufflinks {
        transform: translate(-1.4%, 20%);
    }

    .tiebar {
        transform: translate(9%, -80%);
    }

    .cuff2 {}
}