@media screen and (min-width: 767px) {
    .app {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        background-color: white;
        position: relative;
        padding: 55px;
        gap: 40px;
        width: 1000px;
        height: 450px;
        >h1 {
            display: none;
        }
        .close {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        .imgs {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            overflow-x: hidden;
            overflow-y: scroll;
            gap: 20px;
            height: 400px;

            .imgBox {
                min-width: 400px;
                min-height: 400px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
            .imgBox:nth-child(3) {
                margin-top: 82px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 45%;
            height: 443px;
            position: relative;
            padding-left: 1rem;
            gap: 3rem;
            >h1 {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 30px;
                line-height: 37px;
                letter-spacing: 0.2em;
                color: #000000;
                width: 100%;
                text-align: left;
            }

            >div {
                width: 100%;

                .titleBox {
                    margin: 0 1rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    >span {
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 0.05em;
                        color: #000000;
                    }
                }

                .detail {
                    display: flex;
                    flex-direction: column;
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0.05em;
                    color: #000000;
                    padding-left: 2rem;
                    padding-top: .4rem;
                    gap: .2rem;
                }

                .hr {
                    background-color: #7F7F7F;
                    width: 100%;
                    height: 1px;
                    margin: 1rem 0;
                }
            }

            .total {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.2em;
                color: #000000;
                width: 100%;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .app {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: white;
        position: relative;
        gap: 5vh;
        width: 90vw;
        height: 80vh;
        overflow-y: auto;

        .close {
            position: absolute;
            top: 2%;
            right: 2%;
        }
        >h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            letter-spacing: 0.2em;
            color: #000000;
            width: 100%;
            text-align: center;
        }
        .imgs {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            overflow-y: hidden;
            overflow-x: scroll;
            height: 30vh;
            gap: 2rem;
            .imgBox {
                min-width: 90vw;
                min-height: 30vh;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin: 1rem 1rem;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80%;
            position: relative;
            padding-left: 1rem;

            >h1 {
                display: none;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 30px;
                line-height: 37px;
                letter-spacing: 0.2em;
                color: #000000;
                width: 100%;
                text-align: left;
            }

            >div {
                width: 100%;

                .titleBox {
                    margin: 0 1rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    >span {
                        font-family: 'Nunito Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        letter-spacing: 0.05em;
                        color: #000000;
                    }
                }

                .detail {
                    display: flex;
                    flex-direction: column;
                    font-family: 'Nunito Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    letter-spacing: 0.05em;
                    color: #000000;
                    padding-left: 2rem;
                    padding-top: .4rem;
                    gap: .2rem;
                }

                .hr {
                    background-color: #7F7F7F;
                    width: 100%;
                    height: 1px;
                    margin: 1rem 0;
                }
            }

            .total {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.2em;
                color: #000000;
                width: 100%;
                text-align: right;
            }
        }
    }
}