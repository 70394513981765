.app {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: white;
    height: 100%;
    >div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 25px;
        >p {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
        }
    }
    >div:nth-child(2) {
        border: 2px solid #000000 !important;
        width: 280px;
        height: 16px;
    }
}