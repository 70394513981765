$color_1: #1c1b1b;
$font-family_1: 'Montserrat';
$font-family_2: 'Nunito Sans';
$background-color_1: #F7F7F7;

.app {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $background-color_1;

	>h1 {
		font-family: $font-family_1;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-top: 50px;
		margin-bottom: 16.5px;
		letter-spacing: .1em;
		color: $color_1;
	}

	>h2 {
		font-family: $font-family_2;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 23px;
		text-align: center;
		margin-bottom: 38px;
		margin-top: 0;
		color: $color_1;
	}
}

.products {
	max-width: 86%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(22vw, 1fr));
	padding-bottom: 2rem;

	>div {
		width: 22vw;
		height: 15.81vw;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 30px 30px 0 30px;

		>span {
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			letter-spacing: 0.2em;
			color: $color_1;
			padding-top: 30px;
			text-transform: uppercase;
		}
	}
}

.productsMobile {
	max-width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(40vw, 1fr));
	overflow-y: scroll;
	height: 50vh;

	>div {
		width: 40vw;
		height: 44.4vw;
		margin: 12px;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 5vw 5vw 0 5vw;

		>span {
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			text-align: center;
			letter-spacing: 0.2em;
			color: $color_1;
			padding-top: 20px;
			text-transform: uppercase;
		}
	}
}