$color_1: #000000;
$color_2: #FFFFFF;
$color_3: #333333;
$font-family_1: 'Montserrat';
$font-family_2: 'Nunito Sans';
$background-color_1: #F7F7F7;
$background-color_2: #F7F7F7;

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-color_1;
    height: 100vh;

    >.view {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        background-color: $background-color_2;
        
        .topBtns {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 0px 3%;
            width: 100%;

            >.close {
                @media screen and (min-width: 767px) {
                    font-size: 30px;
                }

                @media screen and (max-width: 767px) {
                    font-size: 20px;
                }

                color: $color_3;
            }

            >.back {
                top: 0px;
                left: 38px;
                font-family: $font-family_1;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.2em;
                color: $color_1;
                text-decoration: auto;
            }
        }

        >h1 {
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 500;
            @media screen and (min-width: 767px) {
                font-size: 20px;
            }

            @media screen and (max-width: 767px) {
                font-size: 18px;
            }
            line-height: 0px;
            letter-spacing: 0.22em;
            color: $color_1;
            text-align: center;
        }

        >h2 {
            font-family: $font-family_2;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: $color_1;
            text-align: center;
        }

        .LaserCustomView {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            @media screen and (min-width: 767px) {
                margin-top: 2rem;
            }

            @media screen and (max-width: 767px) {
                margin-bottom: 2rem;
            }

            >span {
                @media screen and (min-width: 767px) {
                    margin-top: 2rem;
                }

                @media screen and (max-width: 767px) {
                    padding-right: 10%;
                    margin-top: 10px;
                }

                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 23px;
                align-items: center;
                color: #272727;
                padding-right: 1rem;
            }
        }

        >button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px;
            gap: 10px;
            width: 320px;
            height: 45px;
            background: #25241E;
            border: 0px;
            font-family: $font-family_1;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.2em;
            color: $color_2;
            order: 0;
            flex-grow: 0;
            margin: 20px;
        }


    }
}