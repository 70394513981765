.app {
    border-radius: 0px;

    @media screen and (min-width: 767px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        /* width: 100vw;
        height: 95vh;
        padding-top: 5vh;
        padding-bottom: 5vh;
        box-sizing: border-box; */
    }

    @media screen and (min-width: 767px) {
        gap: 60px;
    }

    @media screen and (max-width: 767px) {
        gap: 10px;
    }

    .modal_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .modal_detail {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 767px) {
            width: 520px;
        }

        @media screen and (max-width: 767px) {
            width: 86%;
        }

        >* {
            margin: .4rem;
        }

        >button {
            align-self: stretch;
            background: #1c1b1b;
            border: 1px solid #aaa;
            border-radius: 2px;
            color: #fff;
            font-size: 12px;
            gap: 10px;
            height: 35px;
            line-height: 15px;
            margin-top: 12px;
            //order: 2;
            text-align: center;

            &:hover {
                background: lightgray;
                color: #1C1B1B;
            }
        }

        .price {
            text-align: right;
        }

        >div {
            input {
                padding-left: 14px;
                font-size: 20px;
                text-transform: uppercase;
            }
        }

        .modal_icons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            >div {
                font-size: 1.4rem;
                padding: 0rem .6rem;
            }
        }
    }

    .close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }
}