$color_1: #272727;
$color_2: #FFFFFF;
$color_3: #333333;
$color_4: #000000;
$font-family_1: 'Montserrat';
$font-family_2: 'Nunito Sans';
$background-color_1: #E5E5E5;
$background-color_2: #e5e5e5;

/* pc */
/* On screens that are 600px wide or less, make the columns stack on top of each other instead of next to each other */
.app {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $background-color_1;
	height: 100vh;


	>.view {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		background-color: $background-color_2;

		.topBtns {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 5px 3%;
			width: 100%;

			>.close {
				@media screen and (min-width: 767px) {
					font-size: 30px;
				}

				@media screen and (max-width: 767px) {
					font-size: 20px;
				}

				color: $color_3;
			}

			>.back {
				top: 0px;
				left: 38px;
				font-family: $font-family_1;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				letter-spacing: 0.2em;
				color: $color_1;
				text-decoration: auto;
			}
		}

		>img {
			max-width: 90vw;
		}

		>h1 {
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			letter-spacing: 0.2em;
			color: $color_1;
			margin: 12px;
			width: 80%;
			text-align: center;
		}

		>h2 {
			font-family: $font-family_2;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 23px;
			color: $color_1;
			margin: 12px;
		}

		>button {
			width: 320px;
		}


	}
}

.modal_close {
	position: absolute;
	color: $color_3;
	font-size: 30px;
	right: 20px;
	top: 20px;
}

@media screen and (min-width: 767px) {
	.modal_info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 95%;

		>div {
			>p {
				font-family: $font-family_2;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 23px;
				color: $color_4;
				margin: 0px;
			}

			>h1 {
				font-family: $font-family_1;
				font-style: normal;
				font-weight: 500;
				font-size: 30px;
				line-height: 37px;
				letter-spacing: 0.2em;
				color: $color_4;
				margin-bottom: 20px;
			}

			>h3 {
				font-family: $font-family_2;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				line-height: 23px;
				text-decoration-line: underline;
				color: $color_4;
				margin-top: 30px;
			}
		}
	}

	.modal_detail {
		display: flex;
		flex-direction: column;
		flex-grow: .3;
		height: 60vh;
		padding-left: 150px;

		>h1 {
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 30px;
			line-height: 37px;
			letter-spacing: 0.2em;
			color: $color_4;
			margin-bottom: 20px;
		}

		>h2 {
			text-align: end;
			padding-right: 40px;
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			letter-spacing: 0.2em;
			color: $color_4;
		}

		>div {
			display: flex;
			flex-direction: column;
			height: 80%;
			max-height: 80%;
			overflow-y: scroll;
		}
	}
}

@media screen and (max-width: 767px) {
	.modal_info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		font-size: 12px;

		>div {
			>p {
				font-family: $font-family_2;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 23px;
				color: $color_4;
				margin: 0px;
			}

			>h1 {
				color: #000;
				font-family: Montserrat;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				letter-spacing: .2em;
				line-height: 22px;
				margin-bottom: 0;
				margin-top: 20px;
			}

			>h3 {
				font-family: $font-family_2;
				font-style: normal;
				font-weight: 700;
				font-size: 15px;
				line-height: 23px;
				text-decoration-line: underline;
				color: $color_4;
			}
		}
	}

	.modal_detail {
		display: flex;
		flex-direction: column;
		height: 10%;
		flex-grow: 1;

		>h1 {
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 18px;
			line-height: 37px;
			letter-spacing: 0.2em;
			color: $color_4;
			margin-bottom: 10px;
		}

		>h2 {
			text-align: end;
			padding-right: 0px;
			font-family: $font-family_1;
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 16px;
			letter-spacing: 0.2em;
			color: $color_4;
		}

		>div {
			display: flex;
			flex-direction: column;
			overflow-y: scroll;
			overflow-x: hidden;
		}
	}

}